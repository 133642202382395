import React from "react"
import { graphql } from 'gatsby'
import { themeGet } from 'styled-system'
import styled from 'styled-components'
import MDXRenderer from "gatsby-mdx/mdx-renderer";

import Text from "../components/Text"
import { RelateLink } from "../components/Link"

import Layout from "../layouts"

const Content = styled.div`
	margin: 7em 0;
	width: 100%;
	& p, img {
		width: 100%;
	}
`
const RelatedList = styled.div`
	align-items: flex-start;
	display: grid;
	grid-column: 1 / -1;
	grid-template-columns: repeat(3, 1fr);
	position: relative;
	margin: 4em 0;
	@media (max-width: ${themeGet('breakpoints.2')}) { 
		grid-template-columns: repeat(1, 1fr);
	}
`


class NotesPost extends React.Component {
	renderRelate() {
		const {current, allMdx} = this.props.data;

        let lists = allMdx.edges
	    let relatedList = []

	    for(let i = 0;i < lists.length; i++){
			for(let j = 0;j< current.frontmatter.categories.length; j++) {
	    		if(lists[i].node.frontmatter.categories.indexOf(current.frontmatter.categories[j]) > -1){
		            relatedList.push(lists[i]);
		            break;
		        }
    		}   
	    }

	    return relatedList.map((item, i) => {
	    	return(
	    		<RelateLink to={item.node.fields.slug}>{item.node.frontmatter.title}</RelateLink>
	    	)
	    })
	}

	render() {
		const {current} = this.props.data;
		
		return (
			<Layout>
		 		<Text marginTop={"100px"} fontSize={7} fontWeight="bold">{current.frontmatter.title}</Text>
		 		<Content>
		 			<MDXRenderer>{current.code.body}</MDXRenderer>
		 		</Content>
		 		<Text fontSize={"22px"} fontWeight="bold">Related</Text>
		 		<RelatedList>
		 		{this.renderRelate()}
		 		</RelatedList>
		  	</Layout>
		)
	}
}

export default NotesPost

export const pageQuery = graphql`
  query NotesPostQuery($id: String) {
    current: mdx(id: { eq: $id }) {
      	id
      	frontmatter {
       		title
			templateKey
			date(formatString: "MMMM DD - YYYY")
			categories
			tags
      	}
      	code {
        	body
      	}
    }
    allMdx(filter: {frontmatter: {templateKey: {eq: "notes-post" }}}){
		edges {
			node {
				id
				fields {
					slug
				}
				frontmatter {
					title
					templateKey
					description
					date(formatString: "MMMM DD - YYYY")
					categories
					tags 
				}
			}
		}
	}
  }
`;